































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { CHANGE_PAYABLE_COMPENSATION } from '../store';
import { ChangePayableCompensation, PayableCompensation } from '../types';
import { ApiResponse } from '@/components/types';
import CompensationDatePicker from './CompensationDatePicker.vue';
import DatePicker from '@/components/form/DatePicker.vue';
import DownloadAction from './DownloadAction.vue';

const Compensation = namespace('compensation');

@Component({
  components: {
    CompensationDatePicker,
    DatePicker,
    DownloadAction
  }
})
export default class UpdatePayableCompensationDialog extends Vue {
  @Prop({ type: Object }) payableCompensation?: PayableCompensation;

  @Compensation.Action(CHANGE_PAYABLE_COMPENSATION) changePayableCompensation!: (params: ChangePayableCompensation) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;
  dialog: boolean = false;

  updateCompensation: ChangePayableCompensation = {
    conservatorship_id: '',
    start: '',
    end: '',
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.updateCompensation = {
        conservatorship_id: '',
        start: '',
        end: '',
      };

      // @ts-ignore
      this.$refs.form.reset();
      this.$emit('update:payableCompensation', null);
    }, 200);
  }

  @Watch('payableCompensation')
  watchCompensation(payableCompensation: PayableCompensation | null) {
    if (!payableCompensation) return;

    this.updateCompensation.start = payableCompensation.begin;
    this.updateCompensation.end = payableCompensation.end;
    this.updateCompensation.conservatorship_id = payableCompensation.conservatorshipId;
    // @ts-ignore
    this.$refs.form.reset();

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  save() {
    this.error = null;

    return this.changePayableCompensation(this.updateCompensation);
  }
}
