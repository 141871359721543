



































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import uuid from 'uuid/v4';
import { GET_BY_ID, POSSIBLE_FIXED_RATES, PUT_NEW } from '../store';
import DatePicker from '@/components/form/DatePicker.vue';
import DownloadAction from './DownloadAction.vue';
import CompensationDatePicker from './CompensationDatePicker.vue';
import {
  Compensation,
  PayableCompensation,
  PossibleFixedRates,
  PossibleFixedRatesParams,
  PutNewCompensation
} from '../types';
import { ApiResponse } from '@/components/types';

const Compensation = namespace('compensation');

@Component({
  components: {
    CompensationDatePicker,
    DatePicker,
    DownloadAction
  }
})
export default class PutNewCompensationDialog extends Vue {
  @Prop({ type: Object }) payableCompensation!: PayableCompensation | null;

  @Compensation.Action(PUT_NEW) putNewCompensation!: (params: PutNewCompensation) => Promise<ApiResponse>;
  @Compensation.Action(POSSIBLE_FIXED_RATES) possibleFixedRates!: (params: PossibleFixedRatesParams) => Promise<ApiResponse<PossibleFixedRates>>;
  @Compensation.Getter(GET_BY_ID) getById!: (id: string) => Compensation | undefined;

  error: any = null;
  dialog: boolean = false;

  showFinancialFixedRate: boolean = false;
  showAssumedFixedRate: boolean = false;
  showClosedFixedRate: boolean = false;

  newCompensation: PutNewCompensation = {
    compensation_id: '',
    conservatorship_id: '',
    begin: '',
    end: '',
    for_heirs: false,
    fixed_rates: {
      financial_fixed_rate: false,
      assumed_fixed_rate: false,
      closed_fixed_rate: false
    }
  };

  get compensation(): Compensation | undefined {
    return this.getById(this.newCompensation.compensation_id);
  }

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.error = null;

      this.newCompensation = {
        compensation_id: '',
        conservatorship_id: '',
        begin: '',
        end: '',
        for_heirs: false,
        fixed_rates: {
          financial_fixed_rate: false,
          assumed_fixed_rate: false,
          closed_fixed_rate: false
        }
      };

      this.showFinancialFixedRate = false;
      this.showAssumedFixedRate = false;
      this.showClosedFixedRate = false;

      // @ts-ignore
      this.$refs.form.reset();
      this.$emit('update:payableCompensation', null);
    }, 200);
  }

  @Watch('payableCompensation')
  watchCompensation(payableCompensation: PayableCompensation | null) {
    if (!payableCompensation) return;

    this.newCompensation.begin = payableCompensation.begin;
    this.newCompensation.end = payableCompensation.end;
    this.newCompensation.conservatorship_id = payableCompensation.conservatorshipId;
    this.newCompensation.compensation_id = uuid();
    // @ts-ignore
    this.$refs.form.reset();

    this.possibleFixedRates({ conservatorshipId: payableCompensation.conservatorshipId }).then(({ content }) => {
      if (!content) return;

      this.showFinancialFixedRate = content.showFinancialFixedRare;
      this.showAssumedFixedRate = content.showAssumedFixedRate;
      this.showClosedFixedRate = content.showClosedFixedRate;
    });

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  save() {
    this.error = null;

    return this.putNewCompensation(this.newCompensation);
  }
}
