

















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { REMOVE_PAYABLE_COMPENSATION } from '../store';
import { PayableCompensation, RemovePayableCompensation } from '../types';
import { ApiResponse } from '@/components/types';
import ErrorAlert from '@/components/ErrorAlert.vue';

const Compensation = namespace('compensation');

@Component({
  components: {
    ErrorAlert
  }
})
export default class RemoveNextCompensationDialog extends Vue {
  @Prop({ type: Object }) payableCompensation?: PayableCompensation;

  @Compensation.Action(REMOVE_PAYABLE_COMPENSATION) removeNextCompensation!: (params: RemovePayableCompensation) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;
  dialog: boolean = false;

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) return;

    setTimeout(() => {
      this.$emit('update:payableCompensation', null);
    }, 200);
  }

  @Watch('payableCompensation')
  watchCompensation(payableCompensation: PayableCompensation | null) {
    if (!payableCompensation) return;

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  async save() {
    if (!this.payableCompensation) return;

    this.error = null;
    this.loading = true;

    const { error } = await this.removeNextCompensation({ conservatorship_id: this.payableCompensation.conservatorshipId });

    this.loading = false;

    if (error) {
      this.error = error;
      return;
    }

    this.dialog = false;
  }
}
