






































import { Component, Prop, Vue } from 'vue-property-decorator';
import format from '@/filter/dateFormat';
import { PayableCompensation } from '@/modules/compensation/types';
import { Pagination } from '@/components/types';
import UpdatePayableCompensationDialog from './UpdatePayableCompensationDialog.vue';
import PutNewCompensationDialog from './PutNewCompensationDialog.vue';
import RemovePayableCompensationDialog from './RemovePayableCompensationDialog.vue';
import AppTable from '@/components/AppTable.vue';

@Component({
  components: {
    AppTable,
    RemovePayableCompensationDialog,
    UpdatePayableCompensationDialog,
    PutNewCompensationDialog
  }
})
export default class PayableCompensationTable extends Vue {
  @Prop({ type: Boolean }) loading?: boolean;
  @Prop({ type: Array }) list!: PayableCompensation[];
  @Prop({ type: String }) search?: string;

  pagination: Pagination = {
    itemsPerPage: -1
  };
  nextCompensation: PayableCompensation | null = null;
  removeCompensation: PayableCompensation | null = null;
  changeCompensation: PayableCompensation | null = null;

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.name'), value: 'conservateeName' },
      { align: 'left', text: this.$i18n.t('common.conservator'), value: 'conservator' },
      { align: 'right', text: this.$i18n.t('common.attorneyDocket'), value: 'attorneyDocket' },
      { align: 'right', text: this.$i18n.t('label.dateRange'), value: 'begin' },
      { align: 'right', text: this.$i18n.t('label.putNewCompensation'), value: 'beginTrans', sortable: false },
      { align: 'right', text: this.$i18n.t('common.actions'), value: 'endTrans', sortable: false }
    ];
  }

  get compensations() {
    return this.list.map(({ begin, end, conservatee, ...compensation }: PayableCompensation) => ({
      ...compensation,
      begin,
      end,
      conservatee,
      beginTrans: format(begin, 'dd.MM.yyyy'),
      endTrans: format(end, 'dd.MM.yyyy'),
      conservateeName: `${conservatee.surname}, ${conservatee.forename}`
    }));
  }
}
