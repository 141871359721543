






































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import { FETCH_PAYABLE_LIST } from '../store';
import { SearchableMixin } from '@/views/mixin';
import { ApiResponse } from '@/components/types';
import { PayableCompensation } from '@/modules/compensation/types';
import PayableCompensationTable from '../components/PayableCompensationTable.vue';
import MonthPicker from '@/components/form/MonthPicker.vue';
import { routeToLocation } from '@/router';

const Compensation = namespace('compensation');

@Component({
  components: {
    PayableCompensationTable,
    MonthPicker
  }
})
export default class PayableCompensations extends SearchableMixin {
  @Compensation.State('payable') list!: PayableCompensation[];
  @Compensation.Action(FETCH_PAYABLE_LIST) fetch!: () => Promise<ApiResponse>;

  loading: boolean = false;

  get month(): string | undefined {
    return this.$route.query['month'] as string;
  }

  set month(month: string | undefined) {
    if (!month) {
      const { month, ...query } = this.$route.query;
      this.$router.push(routeToLocation({ ...this.$route, query: { ...query } })).catch(() => {});
      return;
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, month } })).catch(() => {});
  }

  get compensations() {
    return this.list.filter((compensation) => !this.month || format(compensation.end, 'yyyy-MM') <= this.month);
  }

  async created() {
    this.loading = true;

    await this.fetch();

    this.loading = false;
  }
}
